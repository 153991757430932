import moment from 'moment';
import { displayAvailableAppointments } from './displayAvailableAppointments';
import Cookie from "js-cookie";
import { saveCampaign } from './formFunctions';

export function processAppointments(result, apiVersion = 'v2') {
  let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';

  if (typeof result !== 'undefined' && result !== 'No Appointments') {
    console.log("test1");
    console.log(result);
    let dates = [];

    // Get an array of dates without any available appointments
    // --------------------------
    const availableAndFullDates = [];
    const availableDates = [];
    const fullDates = [];
    for (const index in result.seminars) {
      // skip loop if the property is from prototype
      if (!result.seminars.hasOwnProperty(index)) continue;

      const timeSlot = result.seminars[index];
      const dateString = timeSlot.date.replace(/-/g, '/');

      if (typeof availableAndFullDates[dateString] == 'undefined') {
        availableAndFullDates[dateString] = {};
        availableAndFullDates[dateString].full = true;
        availableAndFullDates[dateString].date = timeSlot.date;
        availableAndFullDates[dateString].appointmentSlots = [];
        availableAndFullDates[dateString].promptToCall = false;
      }

      if (timeSlot.blocks.length > 0) {
        availableAndFullDates[dateString].full = false;
      }
      availableAndFullDates[dateString].appointmentSlots.push(timeSlot);

      if (timeSlot.promptToCall != undefined && timeSlot.promptToCall == true) {
        availableAndFullDates[dateString].promptToCall = true;
      }
    }

    for (const index1 in availableAndFullDates) {
      // skip loop if the property is from prototype
      if (!availableAndFullDates.hasOwnProperty(index1)) continue;

      if (availableAndFullDates[index1].full === true) {
        fullDates.push(availableAndFullDates[index1]);
      } else {
        availableDates.push(availableAndFullDates[index1]);
        dates.push(availableAndFullDates[index1].date);
      }
    }

    global.ccUserInfo.availableAndFullDates = availableAndFullDates; // For some reason, the contents of this can't be logged. But it is available if you show global.ccUserInfo
    global.ccUserInfo.availableDates_2 = availableDates; // That obviously needs to be named something else...
    global.ccUserInfo.fullDates = fullDates;
    // --------------------------

    // If no available dates
    // i.e. Dates that are pushed through from SF, but don't have a 'blocks' value (appointment/block id);
    if (dates.length === 0) {
      $('.field-agree-to-terms').hide();
      $('.loading-appointments').hide();
      $('.no-appointments').html('<div class="alert alert-danger">There aren\'t any appointments available. Please call ' + callCenterNumber + ' for assistance.</div>');

      global.dataLayer.push({
        'event': 'No-Appointments Message Displayed',
      });

      return;
    }

    dates.sort();

    // Find out how many appointments they have in the next seven days
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

    var currentDay = new Date();
    var dd = currentDay.getDate();

    var mm = currentDay.getMonth() + 1;
    var yyyy = currentDay.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    currentDay = yyyy + '-' + mm + '-' + dd;


    let appointmentCount = 0;
    //default case of one day out
    let firstAppointmentTime = Math.floor(((new Date().getTime()) / 1000) + 86400);
    let firstAvailableDate;
    var updatedDate = false;
    $.each(result.seminars, (index, time) => {
      if (time.blocks.length > 0) {
        if (new Date(index * 1000) < sevenDaysFromNow) {
          appointmentCount++;
        }

        if (time.date > currentDay) {
          if (!updatedDate) {
            //correct the time for the timezone used by system
            firstAppointmentTime = (parseInt(index) + 25200).toString();
            firstAvailableDate = time.date;
            updatedDate = true;
          }
        }
      }
    });

    global.dataLayer.push({
      'first_appt_date': firstAvailableDate,
    });

    global.dataLayer.push({
      'number_of_appointments': appointmentCount,
    });
    var availableAppointmentsCount = Object.keys(result.seminars).length;
    if (apiVersion === 'v2') {
      global.dataLayer.push({
        'number_of_appointments_total': availableAppointmentsCount,
      });
    }

    // Find out how long till their first appt
    const firstAppt = new Date(firstAppointmentTime * 1000);
    const momentNow = moment();
    const momentAtFirstAppt = moment(firstAppt);
    const firstAptDays = momentAtFirstAppt.diff(momentNow, "days");
    const firstAptHours = momentAtFirstAppt.diff(momentNow, "hours");

    global.dataLayer.push({
      'hours_till_first_appt': firstAptHours,
    });
    global.dataLayer.push({
      'days_till_first_appt': firstAptDays,
    });

    global.dataLayer.push({
      'event': 'Appointments Received',
    });

    // Get the date 8 "Business Days" from the first date.
    let business_days_out = 35;

    // Widen the net for Specific Markets (ACF Options Page)
    if (typeof result.widen_net != 'undefined' && result.widen_net) {
      business_days_out = 64;
    }

    const firstDate = dates[0];
    const lastSFDate = dates[dates.length - 1];
    const lastSalesforceDate = new Date(lastSFDate.replace(/-/g, '/'));
    let endDate = new Date(firstDate.replace(/-/g, '/'));
    let i = 0;

    while (i < business_days_out && endDate <= lastSalesforceDate) {
      endDate = new Date(endDate.setDate(endDate.getDate() + 1));
      if (endDate.getDay() > 0 && endDate.getDay() < 5) {
        i++;
      }
    }

    let tempDate;
    let tempDates = [];
    for (i = dates.length - 1; i >= 0; i--) {
      tempDate = new Date(dates[i].replace(/-/g, '/'));
      if (tempDate < endDate || tempDate.toString() === endDate.toString()) {
        tempDates.push(dates[i]);
      }
    }

    dates = tempDates;
    dates.sort();

    global.ccUserInfo.availableAppointments = result;
    global.ccUserInfo.availableDates = dates;
    global.ccUserInfo.lastAvailableDate = endDate;
    global.ccUserInfo.firstAvailableDate = firstDate;
    global.ccUserInfo.availableAppointmentsRetrieved = true;

    if ($('.loading-appointments').length) {
      displayAvailableAppointments();
    }

    var navigationButtonTest = Cookie.get('test-navigation-button');
    if (navigationButtonTest != undefined && navigationButtonTest == 'true') {
      $(".choose-date-month").addClass("button-test-spacing");
      $("#webFormCalendar a.btnPrev").html('\
        <svg class="test-button-image" width="100%" height="100%" viewBox="0 0 99 99" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\
            <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->\
            <title>Left Button@3x</title>\
            <desc>Created with Sketch.</desc>\
            <g id="R3-Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
                <g id="Selected-Option-Mobile-SEP-Small-Button-Date-Alt" transform="translate(-231.000000, -780.000000)">\
                    <g id="Left-Button" transform="translate(280.500000, 829.500000) rotate(-180.000000) translate(-280.500000, -829.500000) translate(231.000000, 780.000000)">\
                        <circle id="Oval" fill="#015FB9" cx="49.2" cy="49.2" r="49.2"></circle>\
                        <polygon id="Fill-1" fill="#FFFFFF" transform="translate(49.200000, 49.600000) rotate(-270.000000) translate(-49.200000, -49.600000) " points="49.2 37.6 28 58.0146341 31.7992832 61.6 49.2 44.8439024 66.6014767 61.6 70.4 58.0146341"></polygon>\
                    </g>\
                </g>\
            </g>\
        </svg>');
      $("#webFormCalendar a.btnPrev").addClass("circle-nav-btn");
      $("#webFormCalendar a.btnNext").html('\
        <svg class="test-button-image" width="100%" height="100%" viewBox="0 0 99 99" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\
            <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->\
            <title>Right Button@3x</title>\
            <desc>Created with Sketch.</desc>\
            <g id="R3-Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
                <g id="Selected-Option-Mobile-AUG-Small-Button-Date-Alt" transform="translate(-797.000000, -780.000000)">\
                    <g id="Right-Button" transform="translate(797.000000, 780.000000)">\
                        <circle id="Oval" fill="#015FB9" cx="49.2" cy="49.2" r="49.2"></circle>\
                        <polygon id="Fill-1" fill="#FFFFFF" transform="translate(49.200000, 49.600000) rotate(-270.000000) translate(-49.200000, -49.600000) " points="49.2 37.6 28 58.0146341 31.7992832 61.6 49.2 44.8439024 66.6014767 61.6 70.4 58.0146341"></polygon>\
                    </g>\
                </g>\
            </g>\
        </svg>');
      $("#webFormCalendar a.btnNext").addClass("circle-nav-btn");
      saveCampaign('onlineschedfrwdbtntest');
    }
  } else {
    $('.field-agree-to-terms').hide();

    $('.no-appointments').html('<div class="alert alert-danger">There aren\'t any appointments available. Please call ' + callCenterNumber + ' for assistance.</div>');

    global.dataLayer.push({
      'event': 'No-Appointments Message Displayed',
    });
  }
}
