export function inspectletDataAttributes() {
  const forms = $('.gform_wrapper form');

  if (forms.length > 0) {
    const addInspectletDataAttibutes = () => {
      const forms = $('.gform_wrapper form');
      const fields = forms.find('.gfield');

      // Add attribute to form
      forms.each(function() {
        const $this = $(this);

        const className = $ (this).attr('class');

        $this.attr('inspectlet-form-analytics', className);
      });

      // Add attribute to fields
      fields.each(function() {
        const $this = $(this);

        const fieldClasses = $this.attr('class').replace(/\s+/g,' ').trim();
        const classArray = fieldClasses.split(' ');
        let fieldClass = '';

        for (let i= 0; i < classArray.length; i++) {
          if (classArray[ i ].indexOf('field-') === 0) {
            fieldClass = classArray[ i ];
          }
        }

        if (fieldClass !== '') {
          $this.find('input, select').attr('insp-form-input-id', fieldClass);
        }
      });

      // Add attribute to buttons
      forms.find('.gform_next_button').each(function() {
        const $this = $(this);
        const pageID = $this.closest('.gform_page').attr('id');
        const pageNum = pageID.split('_').pop();

        $this.attr('insp-form-input-id', `page-${pageNum}-nextButton`);
      });

      forms.find('.gform_previous_button').addClass('inspnoformanalytics');
    };

    addInspectletDataAttibutes();

    $(document).bind('gform_post_render', () => {
      // code to trigger on AJAX form render
      addInspectletDataAttibutes();
    });
  }
}
